import PropTypes from 'prop-types';
import React from 'react';

import http from 'http.js';

import {HF_FEES_MODULE} from 'core/constants.js';

import CollapsibleText from 'core/components/CollapsibleText.js';
import Icon from 'core/components/Icon.js';
import Tooltip from 'core/components/Tooltip.js';
import ParticipationGraph from 'professional/components/ParticipationGraph.js';
import ParticipationTable from 'professional/components/ParticipationTable.js';
import ProcedureInfo from 'professional/components/ProcedureInfo.js';
import ProfessionalHealthFund from 'professional/models/professional_health_fund.js';

import {ConcatenatedTextV2} from '@HealthShareAU/hs-component-library';

export default class FundInfo extends React.Component {
    static propTypes = {
        colorTheme: PropTypes.shape({color: PropTypes.string.isRequired}),
        dataTestId: PropTypes.string,
        gaObjectType: PropTypes.string,
        gaSelector: PropTypes.string,
        healthFund: PropTypes.object.isRequired,
        info: PropTypes.object,
        isReferrals: PropTypes.bool.isRequired,
        isSpecialist: PropTypes.bool.isRequired,
        onProcedureFeeMount: PropTypes.func,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string.isRequired,
        show: PropTypes.string.isRequired,
        staticUrl: PropTypes.string,
        version: PropTypes.number,
    };

    static defaultProps = {
        version: 1,
    };

    constructor(props) {
        super(props);
        this.state = {
            healthFundData: undefined,
            loading: true,
        };
    }

    componentDidMount() {
        this.loadHealthFundData();
    }

    componentDidUpdate(prevProps) {
        const {healthFund} = this.props;
        if (prevProps.healthFund.id !== healthFund.id) {
            this.setState({loading: true});
            this.loadHealthFundData();
        }
    }

    async loadHealthFundData() {
        const {healthFund, profileId, show} = this.props;
        // eslint-disable-next-line max-len
        const url = `/api/professionals/v1/${profileId}/health-funds/${healthFund.id}/`;
        const data = await http.get({url, data: {show}});
        const healthFundData = new ProfessionalHealthFund();
        healthFundData.load(data);
        this.setState({healthFundData, loading: false});
    }

    getParticipationTooltip() {
        const {info, isReferrals} = this.props;
        return (
            <p>
                {isReferrals
                    ? info?.GAP_SCHEME_PARTICIPATION_TOOLTIP_PRO
                    : info?.GAP_SCHEME_PARTICIPATION_TOOLTIP}
            </p>
        );
    }

    renderTooltip() {
        return (
            <Tooltip
                content={this.getParticipationTooltip()}
                defaultPosition="top"
                timeOut={500}
            >
                <Icon name="info" />
            </Tooltip>
        );
    }

    renderHealthFundAvatar() {
        const {
            healthFund: {avatar, url},
        } = this.props;
        return (
            <div>
                {url ? (
                    <a
                        href={url}
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                    >
                        <img alt="" src={avatar} />
                    </a>
                ) : (
                    <img alt="" src={avatar} />
                )}
            </div>
        );
    }

    renderNoGapSchemeInfo() {
        const {
            healthFund: {noDataText},
        } = this.props;
        if (noDataText) {
            return (
                <div className="hs-fund-info-header">
                    <h3>{'Health fund data'}</h3>
                    {this.renderTooltip()}
                    <p dangerouslySetInnerHTML={{__html: noDataText}} />
                </div>
            );
        }
    }

    renderGapSchemeData(gapSchemes) {
        const {healthFund, info, isSpecialist, profileName} = this.props;
        const showGraph = gapSchemes.every((gapScheme) =>
            Number.isInteger(gapScheme.participationFrequency),
        );
        const firstGapScheme = gapSchemes[0]?.gapScheme;
        const description = firstGapScheme.participationDescription
            ? firstGapScheme.participationDescription
            : firstGapScheme.description;

        return (
            <>
                {description && (
                    <div className="hs-fund-info-header">
                        <h3>{`Information from ${healthFund.name}`}</h3>
                        {this.renderTooltip()}
                        <div dangerouslySetInnerHTML={{__html: description}} />
                    </div>
                )}
                {showGraph ? (
                    <ParticipationGraph
                        gapSchemes={gapSchemes}
                        healthFund={healthFund}
                        profileName={profileName}
                        title={info?.GAP_SCHEME_GRAPH_TITLE}
                    />
                ) : (
                    <ParticipationTable
                        gapSchemes={gapSchemes}
                        isSpecialist={isSpecialist}
                    />
                )}
            </>
        );
    }

    renderProcedureData() {
        const {
            healthFund,
            onProcedureFeeMount,
            profileId,
            profileName,
            version,
        } = this.props;
        const {healthFundData} = this.state;

        if (healthFundData.procedures.length) {
            return (
                <ProcedureInfo
                    healthFund={healthFund}
                    healthFundData={healthFundData}
                    onProcedureFeeMount={onProcedureFeeMount}
                    profileId={profileId}
                    profileName={profileName}
                    version={version}
                />
            );
        } else {
            return null;
        }
    }

    renderDisclaimer(disclaimer) {
        const {colorTheme, version} = this.props;
        if (disclaimer) {
            return (
                <div className="hs-fund-info-footnote">
                    <h2>{'What you should know'}</h2>
                    <small className="hsv2-allow-html-content">
                        {version > 1 ? (
                            <ConcatenatedTextV2
                                colorTheme={colorTheme}
                                defaultEllipsis
                                isCollapsible
                                isShowIcon
                                length={200}
                                text={disclaimer}
                            />
                        ) : (
                            <CollapsibleText
                                maxWords={40}
                                renderHtml={true}
                                text={disclaimer}
                            />
                        )}
                    </small>
                </div>
            );
        }
        return null;
    }

    render() {
        const {dataTestId, gaObjectType, gaSelector, healthFund} = this.props;
        const {loading} = this.state;
        if (loading) {
            return null;
        } else {
            const {
                healthFundData: {professionalGapSchemes},
            } = this.state;
            const fundColour = healthFund?.colorBrand;
            const anyGapSchemes = professionalGapSchemes?.length;
            // eslint-disable-next-line max-len
            const disclaimer =
                professionalGapSchemes[0]?.gapScheme?.disclaimer ??
                healthFund.disclaimer;
            const colorTheme = fundColour ? {color: `#${fundColour}`} : null;
            return (
                <>
                    <figure
                        className="insurance-illustration"
                        data-test-id={dataTestId || HF_FEES_MODULE}
                        data-ga-object-type={gaObjectType}
                        data-ga-selector={gaSelector || HF_FEES_MODULE}
                        style={colorTheme}
                    >
                        {this.renderHealthFundAvatar()}
                        {anyGapSchemes
                            ? this.renderGapSchemeData(professionalGapSchemes)
                            : this.renderNoGapSchemeInfo()}
                        {this.renderProcedureData()}
                        {this.renderDisclaimer(disclaimer)}
                    </figure>
                </>
            );
        }
    }
}
